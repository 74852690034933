import { timeFormatDefaultLocale } from 'd3-time-format';
import moment from 'moment';

import * as api from '@/api';
import * as lastUsedLanguage from '@/api/language';

import { cultures } from '@/localization/cultures';
import { reloadTranslations } from '@/text-db/actions';
import { getCultureByLanguage } from '@jpi-cloud-web-pro/jpi-cloud-shared/utils/getCultureByLanguage';

export const LANGUAGE_SELECTED = 'language/LANGUAGE_SELECTED';
export const selectLanguage = language => async (dispatch, getState) => {
  const culture = getCultureByLanguage(language, cultures);

  const { userInfo } = getState().app;

  timeFormatDefaultLocale(culture.d3TimeLocale);
  moment.locale(culture.momentLocale);

  if (userInfo?.id && !userInfo?.isDemo) {
    await api.updateUserLanguage(userInfo, culture.language);
  }

  dispatch(reloadTranslations(culture.language));

  dispatch({
    type: LANGUAGE_SELECTED,
    selectedLanguage: culture.language,
    languageTag: culture.languageTag,
  });

  lastUsedLanguage.set(culture.language);
};
