interface SortData {
  array: any[];
  key: string;
  order: 'asc' | 'desc';
}

export const sortArrayByLocaleCompare = ({ array = [], key, order }: SortData) => {
  const sortedArray = array.slice().sort((a, b) => {
    return a[key].localeCompare(b[key]);
  });
  order === 'desc' && sortedArray.reverse();
  return sortedArray;
};
