import { Spinner } from 'jpi-cloud-web-ui-kit';
import React from 'react';

import styled from 'styled-components';
import { spinnerDarkColor } from '../../config/theme';

export interface LoaderProps {
  dark?: boolean;
  size?: number;
  color?: string;
  isPage?: boolean;
  ariaLabel?: string;
  justifyContent?: string;
  margin?: string;
}

export type StyleProps = {
  $justifyContent?: string;
  $margin?: string;
  ariaLabel?: string;
};

const SpinnerStyle = styled.div<StyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredSpinner = styled(SpinnerStyle)`
  height: 50vh;
`;

const StyledSpinner = styled(SpinnerStyle)<{ margin: string; justifyContent: string }>`
  margin: ${props => props.margin};
  justify-content: ${props => props.justifyContent};
`;

const Loader = ({
  dark = true,
  size = 6.8,
  color,
  isPage = false,
  ariaLabel = '',
  justifyContent = '',
  margin = '20px 0',
}: LoaderProps) =>
  isPage ? (
    <CenteredSpinner aria-label={ariaLabel}>
      <Spinner center color={dark ? spinnerDarkColor : color} size={size} />
    </CenteredSpinner>
  ) : (
    <StyledSpinner justifyContent={justifyContent} margin={margin} aria-label={ariaLabel}>
      <Spinner center color={dark ? spinnerDarkColor : color} size={size} />
    </StyledSpinner>
  );

export default Loader;
