type Language = {
  language: string;
  dialects: string[];
  messages: Record<string, string>;
  momentLocale: string;
  languageTag: string;
};

export const getCultureByLanguage = (requestedLanguage: string, cultures: Record<string, Language>) => {
  const cultureKey = Object.keys(cultures).find(key => cultures[key].dialects.includes(requestedLanguage));

  return (
    (cultures[cultureKey] && {
      ...cultures[cultureKey],
      messages: { ...cultures.en.messages, ...cultures[cultureKey].messages },
    }) ||
    cultures.en
  );
};
