export const Brands = {
  AIRSITE: 'airsite',
  AIT: 'ait',
  BIAWAR: 'biawar',
  CETETHERM: 'cetetherm',
  CLIMATEMASTER: 'climatemaster',
  CONTURA: 'contura',
  CTA: 'cta',
  CTC: 'ctc',
  DZD: 'dzd',
  DANDELION: 'dandelion',
  ENERTECH: 'enertech',
  HOIAX: 'hoiax',
  IEC: 'iec',
  JAMA: 'jama',
  JASPI: 'jaspi',
  METROTHERM: 'metrotherm',
  MHI: 'mhi',
  MYUPLINK: 'myuplink',
  NIBE: 'nibe',
  NIBEF: 'nibef',
  NOVELAN: 'novelan',
  ROTH: 'roth',
  TEMPEFF: 'tempeff',
  TIKI: 'tiki',
};

export const DEFAULT_BRAND = Brands.MYUPLINK;

const hideBrandForProdAndStageEnv = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging';

export const brandsList = [
  {
    brand: Brands.AIT,
    url: '/assets/brands/ait/ait-logo.svg',
    companyUrl: 'https://www.alpha-innotec.com/',
    hide: false,
  },
  {
    brand: Brands.BIAWAR,
    url: '/assets/brands/biawar/biawar-logo.svg',
    companyUrl: 'https://www.biawar.com.pl/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.CETETHERM,
    url: '/assets/brands/cetetherm/cetetherm-logo.svg',
    companyUrl: 'https://www.cetetherm.com/',
    hide: false,
  },
  {
    brand: Brands.CLIMATEMASTER,
    url: '/assets/brands/climatemaster/climatemaster-logo.svg',
    companyUrl: 'https://www.climatemaster.com/',
    hide: false,
  },
  {
    brand: Brands.CONTURA,
    url: '/assets/brands/contura/contura-logo.svg',
    companyUrl: 'https://www.contura.eu/',
    hide: false,
  },
  { brand: Brands.CTA, url: '/assets/brands/cta/cta-logo.svg', companyUrl: 'https://www.cta.ch', hide: false },
  { brand: Brands.CTC, url: '/assets/brands/ctc/ctc-logo.svg', companyUrl: 'https://ctc-heating.com/', hide: false },
  {
    brand: Brands.DZD,
    url: '/assets/brands/dzd/dzd-logo.svg',
    companyUrl: 'https://dzd.cz/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.DANDELION,
    url: '/assets/brands/dandelion/dandelion-logo.svg',
    companyUrl: 'https://dandelionenergy.com/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.ENERTECH,
    url: '/assets/brands/enertech/enertech-logo.svg',
    companyUrl: 'https://enertechusa.com/',
    hide: false,
  },
  { brand: Brands.HOIAX, url: '/assets/brands/hoiax/hoiax-logo.svg', companyUrl: 'https://www.hoiax.no/', hide: false },
  {
    brand: Brands.IEC,
    url: '/assets/brands/iec/iec-logo.svg',
    companyUrl: 'https://iec-okc.com/',
    hide: false,
  },
  {
    brand: Brands.JAMA,
    url: '/assets/brands/jama/jama-logo.svg',
    companyUrl: 'https://jaspi.fi/',
    hide: false,
  },
  {
    brand: Brands.JASPI,
    url: '/assets/brands/jaspi/jaspi-logo.svg',
    companyUrl: 'https://jaspi.fi/',
    hide: false,
  },
  {
    brand: Brands.METROTHERM,
    url: '/assets/brands/metrotherm/metrotherm-logo.svg',
    companyUrl: 'https://www.metrotherm.dk/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.MHI,
    url: '/assets/brands/mhi/mhi-logo.svg',
    companyUrl: 'https://www.mhi.com',
    hide: hideBrandForProdAndStageEnv,
  },
  { brand: Brands.NIBE, url: '/assets/brands/nibe/nibe-logo.svg', companyUrl: 'https://www.nibe.eu/', hide: false },
  { brand: Brands.NIBEF, url: '/assets/brands/nibef/nibef-logo.svg', companyUrl: 'https://www.nibe.eu/', hide: true },
  {
    brand: Brands.AIRSITE,
    url: '/assets/brands/airsite/airsite-logo.svg',
    companyUrl: 'http://www.nibeairsite.com/sv/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.NOVELAN,
    url: '/assets/brands/novelan/novelan-logo.svg',
    companyUrl: 'https://www.novelan.com/',
    hide: false,
  },
  {
    brand: Brands.ROTH,
    url: '/assets/brands/roth/roth-logo.svg',
    companyUrl: 'https://www.roth-industries.com/',
    hide: false,
  },
  {
    brand: Brands.TEMPEFF,
    url: '/assets/brands/tempeff/tempeff-logo.svg',
    companyUrl: 'https://www.tempeff.com/',
    hide: hideBrandForProdAndStageEnv,
  },
  {
    brand: Brands.TIKI,
    url: '/assets/brands/tiki/tiki-logo.svg',
    companyUrl: 'https://www.tikigroup.eu/',
    hide: hideBrandForProdAndStageEnv,
  },
];

export const timeZoneOffsetRegex = /\(([^)]+)\)/;
export const DEFAULT_LANGUAGE = 'en';
export const address = {
  postalCode: {
    regex: /^[a-zA-Z\d\- ]+$/,
    maxLength: 20,
  },
  cityMaxLength: 100,
  regionMaxLength: 100,
  pattern: /^[\p{L}\p{Nd}'"\-–.,#&/() ]+$/u,
};

export const userNamePattern = /^[\p{L}\p{Nd}'\-. ]+$/u;
export const defaultMaxLength = 255;

export const noRegion = {
  name: 'None',
  code: 'NONE',
};

export const vatNumberFormats = [
  { code: 'AT', vatPrefix: 'AT', vatNumberRegex: new RegExp('^U[0-9]{8}$') }, //Austria
  { code: 'BE', vatPrefix: 'BE', vatNumberRegex: new RegExp('^0[0-9]{9}$') }, //Belgium
  { code: 'BG', vatPrefix: 'BG', vatNumberRegex: new RegExp('^[0-9]{9,10}$') }, //Bulgaria
  { code: 'CY', vatPrefix: 'CY', vatNumberRegex: new RegExp('^[0-9]{8}[A-Z]$') }, // Cyprus
  { code: 'CZ', vatPrefix: 'CZ', vatNumberRegex: new RegExp('^[0-9]{8,10}$') }, //Czech Republic
  { code: 'DE', vatPrefix: 'DE', vatNumberRegex: new RegExp('^[0-9]{9}$') }, //Germany
  { code: 'DK', vatPrefix: 'DK', vatNumberRegex: new RegExp('^[0-9]{8}$') }, //Denmark
  { code: 'EE', vatPrefix: 'EE', vatNumberRegex: new RegExp('^[0-9]{9}$') }, //Estonia
  { code: 'GR', vatPrefix: 'EL', vatNumberRegex: new RegExp('^[0-9]{9}$') }, //Greece
  { code: 'ES', vatPrefix: 'ES', vatNumberRegex: new RegExp('^[0-9A-Z][0-9]{7}[0-9A-Z]$') }, //Spain
  { code: 'FI', vatPrefix: 'FI', vatNumberRegex: new RegExp('^[0-9]{8}$') }, //Finland
  { code: 'FR', vatPrefix: 'FR', vatNumberRegex: new RegExp('^[0-9A-Z]{2}[0-9]{9}$') }, //France
  { code: 'GB', vatPrefix: 'GB', vatNumberRegex: new RegExp('^(([0-9]{9})|([0-9]{12})|(GD[0-9]{3})|(HA[0-9]{3}))$') }, //United Kingdom
  { code: 'HR', vatPrefix: 'HR', vatNumberRegex: new RegExp('^[0-9]{11}$') }, //Croatia
  { code: 'HU', vatPrefix: 'HU', vatNumberRegex: new RegExp('^[0-9]{8}$') }, //Hungary
  {
    code: 'IE',
    vatPrefix: 'IE',
    vatNumberRegex: new RegExp('^(([0-9]([0-9A-Z]|\\*|\\+)[0-9]{5}[A-Z])|([0-9]{7}[A-Z]{2}))$'),
  }, //Ireland
  { code: 'IT', vatPrefix: 'IT', vatNumberRegex: new RegExp('^[0-9]{11}$') }, //Italy
  { code: 'LT', vatPrefix: 'LT', vatNumberRegex: new RegExp('^([0-9]{9}|[0-9]{12})$') }, //Lithuania
  { code: 'LU', vatPrefix: 'LU', vatNumberRegex: new RegExp('^[0-9]{8}$') }, //Luxembourg
  { code: 'LV', vatPrefix: 'LV', vatNumberRegex: new RegExp('^[0-9]{11}$') }, //Latvia
  { code: 'MT', vatPrefix: 'MT', vatNumberRegex: new RegExp('^[0-9]{8}$') }, //Malta
  { code: 'NL', vatPrefix: 'NL', vatNumberRegex: new RegExp('^[0-9]{9}B[0-9]{2}$') }, //Netherlands
  { code: 'PL', vatPrefix: 'PL', vatNumberRegex: new RegExp('^[0-9]{10}$') }, //Poland
  { code: 'PT', vatPrefix: 'PT', vatNumberRegex: new RegExp('^[0-9]{9}$') }, //Portugal
  { code: 'RO', vatPrefix: 'RO', vatNumberRegex: new RegExp('^[0-9]{2,10}$') }, //Romania
  { code: 'SE', vatPrefix: 'SE', vatNumberRegex: new RegExp('^[0-9]{12}$') }, //Sweden
  { code: 'SI', vatPrefix: 'SI', vatNumberRegex: new RegExp('^[0-9]{8}$') }, //Slovenia
  { code: 'SK', vatPrefix: 'SK', vatNumberRegex: new RegExp('^[0-9]{10}$') }, //Slovakia
];
