import {
  GET_ACTIVE_PRO_ACCOUNTS_RESPONSE,
  GET_ALL_BRANDS_FAILED,
  GET_ALL_BRANDS_RESPONSE,
  GET_CLOUD_STATUS_RESPONSE,
  GET_COUNTRIES_RESPONSE,
  GET_LAST_USED_LANGUAGE_RESPONSE,
  GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE,
  GET_USER_INFO_RESPONSE,
  GET_USER_SYSTEMS_RESPONSE,
  RENAME_SYSTEM_REQUEST,
  SELECT_SYSTEM,
  SET_APP_LOADED_STATUS,
  SET_APP_LOADING_STATUS,
  SET_LANGUAGES,
  SET_PRO_ACCOUNT,
  SET_PRO_ACCOUNT_ERROR,
  SET_USER_DATA_STATE,
  SET_USER_INFO_SUBSCRIPTION,
  USER_AUTH_STATUS_CHANGED,
} from './actions';

import { ISSUE_TOKEN_RESPONSE_SP, LOGOUT_USER, SET_USER_LOGGED_IN } from '@/pages/Login/actions';

import { CHANGE_USER_PROFILE_RESPONSE_SUCCEDED } from '@/pages/ProfilePage/components/ProfileTab/actions';
import { CHANGE_ADDRESS_RESPONSE_SUCCEDED } from '@/pages/ProfilePage/components/ProfileTab/components/AddressSection/actions';

import { ROLES } from '@/config/user';
import { UserDataState } from './types';

const initialState = {
  url: {}, // TODO: Check and redefine
  userLoggedIn: false,
  userInfo: null,
  activeProAccounts: [],
  systems: [],
  selectedSystem: null,
  countries: [],
  latestAgreements: [],
  status: {},
  brands: [],
  lastUsedLanguage: 'en',
  appLoaded: false,
  appLoading: false,
  servicePartner: null,
  userDataState: UserDataState.EMPTY,
  allowRedirect: true,
};

const renameSystem = (state = initialState, system, name) => {
  const systems = state.systems.map(s => {
    if (s.id === system.id) {
      return { ...s, name };
    }
    return s;
  });

  if (state.selectedSystem && state.selectedSystem.id === system.id) {
    const selectedSystem = { ...state.selectedSystem, name };
    return { ...state, systems, selectedSystem };
  }

  return { ...state, systems };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_USER_PROFILE_RESPONSE_SUCCEDED:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          fullName: action.userProfile.fullName,
          unitSystem: action.userProfile.unitSystem,
        },
      };
    case GET_USER_SYSTEMS_RESPONSE:
      return { ...state, systems: action.systems };
    case SELECT_SYSTEM:
      return { ...state, selectedSystem: action.system };
    case SET_PRO_ACCOUNT:
      return { ...state, servicePartner: { ...state.servicePartner, ...action.servicePartner } };
    case SET_PRO_ACCOUNT_ERROR:
      return { ...state, servicePartner: null };

    case GET_USER_INFO_RESPONSE:
      return {
        ...state,
        userInfo: action.userInfo
          ? {
              ...action.userInfo,
              // TODO: Refactor this as inappropriate implementation
              spSubscription: action.userInfo.spSubscription && action.userInfo.spSubscription.toLowerCase() === 'true',
              spRoles: action.userInfo.spRoles?.length > 0 ? action.userInfo.spRoles : [ROLES.guest],
            }
          : initialState.userInfo,
      };
    case GET_ACTIVE_PRO_ACCOUNTS_RESPONSE:
      return { ...state, activeProAccounts: action.activeProAccounts };
    case SET_USER_INFO_SUBSCRIPTION:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          recurringPayment: action.payload,
        },
      };
    case USER_AUTH_STATUS_CHANGED:
      return { ...state, userLoggedIn: action.loggedIn, url: action.url };
    case GET_COUNTRIES_RESPONSE:
      return { ...state, countries: action.countries };
    case GET_CLOUD_STATUS_RESPONSE:
      return { ...state, status: action.status };
    case GET_LATEST_AGREEMENTS_VERSIONS_RESPONSE:
      return { ...state, latestAgreements: action.agreements };
    case GET_ALL_BRANDS_RESPONSE:
      return { ...state, brands: action.brands };
    case GET_ALL_BRANDS_FAILED:
      return { ...state, brands: [] };
    case ISSUE_TOKEN_RESPONSE_SP:
      return { ...state, userLoggedIn: true };
    case SET_USER_LOGGED_IN:
      return { ...state, userLoggedIn: true };
    case LOGOUT_USER:
      return {
        ...state,
        userInfo: null,
        selectedSystem: null,
        servicePartner: null,
        userLoggedIn: false,
        systems: [],
        brands: [],
        allowRedirect: Boolean(action.allowRedirect),
        // userDataState: UserDataState.EMPTY,
      };
    case CHANGE_ADDRESS_RESPONSE_SUCCEDED:
      return { ...state, userInfo: { ...state.userInfo, address: action.address } };
    case RENAME_SYSTEM_REQUEST:
      return renameSystem(state, action.system, action.name);
    case GET_LAST_USED_LANGUAGE_RESPONSE:
      return { ...state, lastUsedLanguage: action.lastUsedLanguage };
    case SET_APP_LOADED_STATUS:
      return { ...state, appLoaded: action.status };
    case SET_APP_LOADING_STATUS:
      return { ...state, appLoading: action.status };
    case SET_USER_DATA_STATE:
      return { ...state, userDataState: action.state };
    case SET_LANGUAGES:
      return { ...state, languages: action.languages };
    default:
      return state;
  }
};
