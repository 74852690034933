import { Brands } from '../../../config/common';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.HOIAX,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#1E97D0',
    secondary: '#222B3D',
  },
};

export default theme;
