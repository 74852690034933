import { Brands } from '../../../config/common';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.AIRSITE,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#1E4C9C',
    secondary: '#1E4C9C',
  },
};

export default theme;
