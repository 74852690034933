import { useSelector } from '@/store';
import { useMemo } from 'react';

type Brand = {
  brandId: string;
  approvalStatus: string;
  edited: string;
};

export type ActiveProAccountType = {
  servicePartnerId: number;
  servicePartnerName: string;
  toSAcceptedNibe: number;
  toSAcceptedMyUptech: number;
  organizationId: number;
  pushNotifications: boolean;
  regularNotifications: number;
  role: number;
  roles: string[];
  userId: string;
  brands: Brand[];
  emailNotifications: boolean;
};

type ActiveProAccountsType = ActiveProAccountType[];

const useActiveProAccounts = (): ActiveProAccountsType => {
  const accounts = useSelector(({ app }) => app.activeProAccounts);

  return useMemo(() => (Array.isArray(accounts) ? accounts : []), [accounts]);
};

export default useActiveProAccounts;
