import { ThemeProvider as ComponentsThemeprovider } from 'jpi-cloud-web-ui-kit';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ThemeProvider as AppThemeProvider } from 'styled-components';

import events, { eventEmitter } from '@/events';

import defaultTheme from '@jpi-cloud-web-pro/jpi-cloud-shared/components/SharedThemeWrapper/theme';
import * as themes from '@jpi-cloud-web-pro/jpi-cloud-shared/components/SharedThemeWrapper/themes';

import { DEFAULT_BRAND } from '@jpi-cloud-web-pro/jpi-cloud-shared/config/common';

// const isDev = process.env.NODE_ENV === 'development';

const brandThemeMap = {
  myuplink: defaultTheme,
  ...themes,
};

// FIXME: Move brand to context and load theme from context based brandId
export function ThemeWrapper({ children }) {
  const [brandTheme, setBrandTheme] = React.useState(DEFAULT_BRAND);

  useEffect(() => {
    // TODO: Attach event listeners and handle events
    eventEmitter.on(events.theme.SET, setBrandTheme);

    return () => {
      eventEmitter.off(events.theme.SET, setBrandTheme);
    };
  }, []);

  const theme = brandThemeMap[brandTheme] || defaultTheme;

  // eslint-disable-next-line no-console
  // if (isDev) console.log('Theme: ', theme.name);

  return (
    <AppThemeProvider theme={theme}>
      <ComponentsThemeprovider theme={theme}>{children}</ComponentsThemeprovider>
    </AppThemeProvider>
  );
}

ThemeWrapper.propTypes = {
  children: PropTypes.node,
};

export default ThemeWrapper;
