import { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import events, { eventEmitter } from '@/events';

import { DEFAULT_BRAND } from '@jpi-cloud-web-pro/jpi-cloud-shared/config/common';

function useTheme() {
  const theme = useContext(ThemeContext);

  const setTheme = brandId => {
    eventEmitter.emit(events.theme.SET, brandId?.toLowerCase() || DEFAULT_BRAND);
  };

  return { theme, setTheme };
}

export default useTheme;
