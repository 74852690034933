import { getFromLocalStorageByKey, removeFromLocalStorage, saveToLocalStorage } from '.';
import { getMenuIdFromLocalStorage, removeMenuLevelsHistoryFromLocalStorage } from './lastUsedDeviceMenu';

const LAST_USED_SYSTEM = 'LAST_USED_SYSTEM';

export const setLastUsedSystemId = systemId => {
  if (systemId !== getLastUsedSystemId()) {
    removeMenuLevelsHistoryFromLocalStorage();
    getMenuIdFromLocalStorage();
  }
  saveToLocalStorage(LAST_USED_SYSTEM, systemId);
};

export const getLastUsedSystemId = () => getFromLocalStorageByKey(LAST_USED_SYSTEM);

export const removeLastUsedSystemId = () => removeFromLocalStorage(LAST_USED_SYSTEM);
