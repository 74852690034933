import { Brands } from '../../../config/common';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.TEMPEFF,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#0076BB',
    secondary: '#F15623',
  },
};

export default theme;
