import { Brands } from '../../../config/common';
import mainTheme from '../theme';

const theme = {
  ...mainTheme,
  name: Brands.CTC,
  COLORS: {
    ...mainTheme.COLORS,
    primary: '#00693E',
    secondary: '#00693E',
  },
};

export default theme;
