import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';

import { getDeviceFeatures } from '@/components/FeaturesFlags/actions';
import { menuSystem } from '@/components/FeaturesFlags/menuSystem';
import { sortArrayByLocaleCompare } from '@jpi-cloud-web-pro/jpi-cloud-shared/utils/sortArrayUtil';

import { selectLanguage } from './actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logger from '@/utils/logger';

import './languageselector.scss';

// TODO: Refactor and merge with CustomLanguageSelector
export function LanguageSelector({
  menuType,
  languages = [],
  selectedLanguage,
  selectedSystem,
  selectLanguage,
  getDeviceFeatures,
}) {
  const changeLanguage = async e => {
    e.preventDefault();

    try {
      const { value: newLanguage } = e.target;

      await selectLanguage(newLanguage);

      const [mainDevice] = selectedSystem?.devices || [];

      if (mainDevice?.id && menuType === menuSystem.simplified) {
        await getDeviceFeatures(mainDevice.id);
      }
    } catch (error) {
      NotificationManager.error(
        <FormattedMessage
          id="profile-settings.profile-info.error"
          defaultMessage="There is an error with your user profile information. Please go to profile page and update accordingly."
        />,
      );

      logger.toAppInsights(error);
    }
  };

  const sortedLanguageOptions = sortArrayByLocaleCompare({ array: languages, key: 'displayName', order: 'asc' });

  return (
    <div className="languageSelector">
      <select
        aria-label="language-selector"
        className="languageSelector__selectControl"
        onChange={changeLanguage}
        value={selectedLanguage}
      >
        {sortedLanguageOptions.length > 0 ? (
          sortedLanguageOptions.map(({ id, name, displayName }) => {
            const [lang] = name.split('-');
            return (
              <option key={id} value={lang}>
                {displayName}
              </option>
            );
          })
        ) : (
          <option value="en">English</option>
        )}
      </select>
      <FontAwesomeIcon icon="globe-americas" className="languageSelector__globeIcon" />
    </div>
  );
}

LanguageSelector.propTypes = {
  selectLanguage: PropTypes.func,
  selectedLanguage: PropTypes.string,
  selectedSystem: PropTypes.object,
  getDeviceFeatures: PropTypes.func.isRequired,
  menuType: PropTypes.string.isRequired,
  languages: PropTypes.array,
};

const mapStateToProps = ({
  app: { languages, selectedSystem },
  language: { selectedLanguage },
  features: { deviceFeatures: { menuSystem } = {} },
}) => ({
  languages,
  selectedLanguage,
  menuType: menuSystem,
  selectedSystem,
});

const mapDispatchToProps = { selectLanguage, getDeviceFeatures };

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
