import React, { useEffect } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { Carousel } from 'jpi-cloud-web-ui-kit';

import { Link } from '@/components/Link';
import Spacer from '@/components/Spacer';
import useTheme from '@/components/ThemeWrapper/useTheme';

import WorksWithBrands from '@/pages/WorksWith/CompanyImages/WorksWithBrands';

import Footer from '../Footer';

import GuestLanguageSelector from '../LanguageSelector/GuestLanguageSelector';

import myUplinkLogo from '@/assets/img/myUplinkPro_logo.png';
import findAndContactImage from '@/assets/img/pro-find-and-contact.png';
import getTheAppImage from '@/assets/img/pro-get-the-app.png';
import monitorImage from '@/assets/img/pro-monitor.png';
import remoteConnectImage from '@/assets/img/pro-remote-connection.png';

import { APP_STATUS_IDLE } from '@/config/common';
import { DEFAULT_BRAND } from '@jpi-cloud-web-pro/jpi-cloud-shared/config/common';

import './signedoutlayout.scss';

export const SignOutLayout = ({ children, url, status, isNotAuthPage, languageSelected }) => {
  const { formatMessage } = useIntl();
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    if (theme.name !== DEFAULT_BRAND) setTheme(DEFAULT_BRAND);
  }, [theme.name]);

  const slides = [
    {
      image: remoteConnectImage,
      title: 'slide.pro.title.remote-connection',
      text: 'slide.pro.text.remote-connection',
    },
    {
      image: monitorImage,
      title: 'slide.pro.title.monitor',
      text: 'slide.pro.text.monitor',
    },
    {
      image: findAndContactImage,
      title: 'slide.pro.title.find-and-contact',
      text: 'slide.pro.text.find-and-contact',
    },
    {
      image: getTheAppImage,
      title: 'slide.pro.title.get-the-app',
      text: 'slide.pro.text.get-the-app',
    },
    {
      imageComponent: <WorksWithBrands />,
      title: 'slide.title.works-with',
      text: 'slide.text.works-with-simple',
      alt: 'Company logo with whom we work',
      buttonText: 'slide.button.works-with',
      isWorksWith: true,
    },
  ].map(slide => ({
    ...slide,
    title: formatMessage({ id: slide.title, defaultMessage: slide.title }),
    text: formatMessage({ id: slide.text, defaultMessage: slide.text }),
    alt: slide.alt ? formatMessage({ id: slide.alt, defaultMessage: slide.alt }) : undefined,
    buttonText: slide.buttonText
      ? formatMessage({ id: slide.buttonText, defaultMessage: slide.buttonText })
      : undefined,
  }));

  return (
    <div className="welcomePage">
      <div className={`welcomePage__wrapper ${isNotAuthPage ? 'fullHeight' : ''}`}>
        {/* FIXME: Refactor app statuses presentation and setup localizationn */}
        {status?.statusCode?.value !== APP_STATUS_IDLE && <div className="status-banner">{status.statusText}</div>}

        <main className={`welcomePage__contentWrapper ${isNotAuthPage ? 'padding-none' : ''}`}>
          {isNotAuthPage ? (
            // TODO: Move to GuestLayout
            <div className="legalContent__page">
              <div className="legalContent__page__header__wrapper">
                <div className="legalContent__page__header">
                  <div className="logo">
                    <Link to="/">
                      <img src={myUplinkLogo} alt="MyUplink PRO Logo" />
                    </Link>
                  </div>
                  <GuestLanguageSelector showShortcodes={false} showIcon={false} isLoginHeader={true} />
                </div>
              </div>

              <section className="legalContent__page__content fullWidth">
                {/* TODO: Refactor content wrapper for auth and legal pages */}
                <div className="welcomePage__formWrapperContent">
                  {children}
                  <div className="welcomePage__spacer"></div>
                </div>
                <Spacer y={124} />
              </section>
            </div>
          ) : (
            <>
              <div className="welcomePage__logoWrapper">
                <Link to="/">
                  <img className="welcomePage__logo" src={myUplinkLogo} alt="MyUplink PRO Logo" />
                </Link>
              </div>
              <section className="welcomePage__slideshow">
                <Carousel padding={[6, 8, 6]} slideHeight={[52.5, 52.5, 'auto']} slides={slides} />
              </section>
              <section className="welcomePage__formWrapper">
                <div className="welcomePage__formWrapperContent">
                  <div className="welcomePage__menuWrapper">
                    <a href={url && url.WEB_URL} className="welcomePage__menuLink">
                      <FormattedMessage id="unauth.menu.private" defaultMessage="Consumers" />
                    </a>
                    <a href={url && url.WEB_PRO_URL} className="welcomePage__menuLink active">
                      <FormattedMessage id="unauth.menu.professional" defaultMessage="Professionals" />
                    </a>
                    <a href={url && url.WEB_DEV_URL} className="welcomePage__menuLink">
                      <FormattedMessage id="unauth.menu.developer" defaultMessage="Developers" />
                    </a>
                    <GuestLanguageSelector className="welcomePage__menuLink lang-desk" />
                  </div>

                  {/* TODO: Refactor content wrapper for auth and legal pages */}
                  {children}
                  <div className="welcomePage__spacer"></div>
                </div>
              </section>
            </>
          )}
        </main>

        <Footer
          isPremiumButtonVisible={false}
          isAdmin={false}
          products={{}}
          isWelcome={true}
          languageSelected={languageSelected}
        />
      </div>
    </div>
  );
};

SignOutLayout.propTypes = {
  isNotAuthPage: PropTypes.bool,
  languageSelected: PropTypes.string,
  status: PropTypes.object,
  children: PropTypes.node,
  url: PropTypes.object,
};

const mapStateToProps = state => ({
  languageSelected: state.language.selectedLanguage,
  status: state.app.status,
  url: state.app.url,
});

export default connect(mapStateToProps, null)(SignOutLayout);
