export const getFromLocalStorageByKey = key => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

export const saveToLocalStorage = (key, value) => {
  if (!key) return null;

  window.localStorage.setItem(key, JSON.stringify(value));
};

export const removeFromLocalStorage = key => {
  window.localStorage.removeItem(key);
};

export const clearStorage = () => {
  window.localStorage.clear();
};
