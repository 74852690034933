import { EDIT_MENU_TOGGLE_SET_VALUE, GET_DEVICE_MENU_RESPONSE } from './actions';

const initialState = {};

const saveDeviceMenu = (state, deviceId, menuId, menu) => {
  if (state[deviceId]) {
    return { ...state, [deviceId]: { ...state[deviceId], [menuId]: menu } };
  }
  return { ...state, [deviceId]: { [menuId]: menu } };
};

const setToggleValue = (state, action) => {
  const { deviceId, menuId, parameterId, value } = action;
  if (state[deviceId]?.[menuId]?.rows) {
    const menu = {
      ...state[deviceId][menuId],
      rows: state[deviceId][menuId].rows.map(row => {
        if (row[parameterId]) {
          row.value = { ...value };
        }

        return row;
      }),
    };

    return { ...state, [deviceId]: { ...state[deviceId], [menuId]: menu } };
  }
  return state;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICE_MENU_RESPONSE:
      return saveDeviceMenu(state, action.deviceId, action.id, action.menu);
    case EDIT_MENU_TOGGLE_SET_VALUE:
      return setToggleValue(state, action);
    default:
      return state;
  }
};
