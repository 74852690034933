import moment from 'moment';
import {
  CLEAN_SYSTEM_HISTORY_CHARTS,
  DELETE_CHART,
  DELETE_CHART_FOR_SECOND_CHART,
  DateRange,
  FULL_SCREEN,
  GET_DEVICES_PARAMETERS_REQUEST,
  GET_DEVICES_PARAMETERS_RESPONSE,
  GET_POINTS_FOR_PARAMETER_RESPONSE,
  GET_POINTS_FOR_PARAMETER_RESPONSE_FAILED,
  GET_POINTS_FOR_PARAMETER_RESPONSE_FAILED_FOR_SECOND_CHART,
  GET_POINTS_FOR_PARAMETER_RESPONSE_FOR_SECOND_CHART,
  SET_CACHED_PARAMETERS_RESPONSE,
  SET_CACHED_PARAMETERS_RESPONSE_FOR_SECOND_CHART,
  SET_DATE_RANGE,
  SET_DATE_RANGE_FOR_SECOND_CHART,
  SET_LOADING_CHART,
  SET_PRELOADING_CHART,
} from './actions';

import { LOGOUT_USER } from '@/pages/Login/actions';

export const initialState = {
  parameters: null,
  charts: [],
  secondChart: [],
  dateRange: DateRange.Day,
  dateFrom: moment().startOf('day'),
  dateTo: moment(),
  dateRangeForSecondChart: DateRange.Day,
  dateFromForSecondChart: moment().startOf('day'),
  dateToForSecondChart: moment(),
  fullScreen: '',
  loading: '',
  preloading: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICES_PARAMETERS_REQUEST:
      return { ...initialState, parameters: null };
    case GET_DEVICES_PARAMETERS_RESPONSE:
      return { ...state, parameters: action.data };
    case SET_CACHED_PARAMETERS_RESPONSE: {
      return {
        ...state,
        parameters: action.data,
        charts: action.chart,
        dateRange: action.dateRange,
        dateFrom: action.dateFrom,
        dateTo: action.dateTo,
      };
    }
    case SET_CACHED_PARAMETERS_RESPONSE_FOR_SECOND_CHART: {
      return {
        ...state,
        parameters: action.data,
        secondChart: action.chart,
        dateRangeForSecondChart: action.dateRange,
        dateFromForSecondChart: action.dateFrom,
        dateToForSecondChart: action.dateTo,
      };
    }
    case SET_PRELOADING_CHART:
      return { ...state, preloading: action.chartNumber };
    case SET_LOADING_CHART:
      return { ...state, loading: action.chartNumber };
    case GET_POINTS_FOR_PARAMETER_RESPONSE:
      return {
        ...state,
        loading: '',
        preloading: '',
        charts: [
          ...state.charts,
          {
            deviceId: action.deviceId,
            device: action.device,
            parameterId: action.parameterId,
            parameter: action.parameter,
            unit: action.unit,
            unitText: action.unitText,
            points: action.points,
            precedingPoint: action.precedingPoint,
            category: action.category,
            chartType: action.chartType,
          },
        ],
      };
    case GET_POINTS_FOR_PARAMETER_RESPONSE_FAILED:
      return {
        ...state,
        loading: '',
        preloading: '',
        charts: [
          ...state.charts,
          {
            deviceId: action.deviceId,
            device: action.device,
            parameterId: action.parameterId,
            parameter: action.parameter,
            unit: action.unit,
            unitText: action.unitText,
            points: action.points,
          },
        ],
      };
    case GET_POINTS_FOR_PARAMETER_RESPONSE_FOR_SECOND_CHART:
      return {
        ...state,
        loading: '',
        preloading: '',
        secondChart: [
          ...state.secondChart,
          {
            deviceId: action.deviceId,
            device: action.device,
            parameterId: action.parameterId,
            parameter: action.parameter,
            unit: action.unit,
            unitText: action.unitText,
            points: action.points,
            precedingPoint: action.precedingPoint,
            category: action.category,
            chartType: action.chartType,
          },
        ],
      };
    case GET_POINTS_FOR_PARAMETER_RESPONSE_FAILED_FOR_SECOND_CHART:
      return {
        ...state,
        loading: '',
        preloading: '',
        secondChart: [
          ...state.secondChart,
          {
            deviceId: action.deviceId,
            device: action.device,
            parameterId: action.parameterId,
            parameter: action.parameter,
            unit: action.unit,
            unitText: action.unitText,
            points: action.points,
          },
        ],
      };
    case DELETE_CHART:
      return {
        ...state,
        charts: state.charts.filter(c => c.deviceId !== action.deviceId || c.parameterId !== action.parameterId),
      };
    case SET_DATE_RANGE:
      return { ...state, dateRange: action.dateRange, dateFrom: action.dateFrom, dateTo: action.dateTo };
    case DELETE_CHART_FOR_SECOND_CHART:
      return {
        ...state,
        secondChart: state.secondChart.filter(
          c => c.deviceId !== action.deviceId || c.parameterId !== action.parameterId,
        ),
      };
    case SET_DATE_RANGE_FOR_SECOND_CHART:
      return {
        ...state,
        dateRangeForSecondChart: action.dateRange,
        dateFromForSecondChart: action.dateFrom,
        dateToForSecondChart: action.dateTo,
      };
    case FULL_SCREEN:
      return { ...state, fullScreen: action.fullScreen };
    case CLEAN_SYSTEM_HISTORY_CHARTS:
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};
