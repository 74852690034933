import { tzToAlpha2countryCode } from '@jpi-cloud-web-pro/jpi-cloud-shared/constants/languages';
import { alpha2CountryCodesWhereAmPmHours, weekStartsOnSunday } from '../config/countries';

export const isWeekStartsOnSunday = countryCode => {
  if (!countryCode) return false;

  return weekStartsOnSunday.includes(countryCode);
};

export const isCountryUseAmPmHours = alpha2CountryCode => {
  if (!alpha2CountryCode) return false;

  return alpha2CountryCodesWhereAmPmHours.includes(alpha2CountryCode);
};

export const getCurrentCountryCode = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return tzToAlpha2countryCode[timeZone];
};
