import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Link } from '@/components/Link';

import UserPermissionsContext from './UserPermissionsContext';

import DesktopMenu from './components/DesktopMenu';
import MobileMenu from './components/MobileMenu';

import { emitClarityTrackEvent, headerTrackingEvents } from '@/components/tracking/Clarity';

import { logoutUser } from '@/pages/Login/actions';
import { getIotStoreRedirectUrl } from './actions';

import { BRANDS_WITH_WEBSHOP_ACTIVE } from '@/config/common';
import { guestMainMenu, mainMenu } from './constants';

import myUplinkLogo from '@/assets/img/myUplinkPro_logo.png';

import './topmenu.scss';

const Logo = ({ userInfo }) => (
  <div className="logo">
    <Link
      to={userInfo?.spSubscription ? '/' : '/no-subscription'}
      preNavigationCallback={() => emitClarityTrackEvent(headerTrackingEvents.HEADER_LOGO_CLICK)}
    >
      <img alt="myUplink Logo" src={myUplinkLogo} />
    </Link>
  </div>
);

Logo.propTypes = {
  userInfo: PropTypes.object,
};

export const Header = ({
  userInfo,
  activeProAccounts,
  selectedSystem,
  servicePartner,
  getIotStoreRedirectUrl,
  products,
  logout,
}) => {
  const { push } = useHistory();
  const spRoles = userInfo?.spRoles || [];

  const servicePartnerBrandIds = servicePartner?.brands?.map(brand => brand.brandId) || [];
  const showStore = servicePartnerBrandIds.every(brand => BRANDS_WITH_WEBSHOP_ACTIVE.includes(brand));

  // FIXME: How this can be decision maker for "disabled" link on global level when we ONLY fetch it on subscriptions???
  const [{ subscription: productSubscriptionDetails = {} } = {}] = products.items || [];

  const menuLayout = !userInfo ? guestMainMenu : mainMenu;

  const userPermissionsContext = {
    productSubscriptionDetails,
    userPermissions: { spRoles, spPayments: userInfo?.recurringPayment },
    spIsEfficiencyPartner: servicePartner?.isEfficiencyPartner,
    redirectTokenFunc: getIotStoreRedirectUrl,
    brands: servicePartner?.brands || [],
    selectedSystemId: selectedSystem?.id,
    spAddress: servicePartner?.address,
    activeProAccounts,
    showStore,
  };

  const handleUserLogout = () => {
    logout(false);

    emitClarityTrackEvent(headerTrackingEvents.HEADER_LOGOUT);

    push('/login');
  };

  return (
    <header className="header-container">
      <div className="header-container-content">
        <UserPermissionsContext.Provider value={userPermissionsContext}>
          <Logo userInfo={userInfo} />
          <DesktopMenu menuItems={menuLayout} selectedSystem={selectedSystem} logoutUser={handleUserLogout} />
          {/* TODO: Add check and do NOT render Mobile menu trigger on Desktop and vice-versa */}
          <div className="mobileMenu">
            <MobileMenu menuItems={menuLayout} selectedSystem={selectedSystem} logoutUser={handleUserLogout} />
          </div>
        </UserPermissionsContext.Provider>
      </div>
    </header>
  );
};

Header.propTypes = {
  userInfo: PropTypes.object,
  activeProAccounts: PropTypes.array,
  servicePartner: PropTypes.object,
  selectedSystem: PropTypes.object,
  products: PropTypes.object.isRequired,
  getIotStoreRedirectUrl: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  app: { userInfo, activeProAccounts, selectedSystem, servicePartner },
  shop: { products },
}) => ({
  userInfo,
  activeProAccounts,
  selectedSystem,
  servicePartner,
  products,
});

const mapDispatchToProps = {
  logout: logoutUser,
  getIotStoreRedirectUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
